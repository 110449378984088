import React from 'react';

import Contact from '../modules/pages/contact/Contact';

const contact = () => {
  return (
    <Contact
      seo={{
        title: 'Contact us',
        description:
          'Understanding your customer has never been so easy! Integrate today and create your analysis in a few minutes.',
      }}
    />
  );
};

export default contact;
